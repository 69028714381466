<template>
  <div class="suggestList">
    <suggest-complaint />
  </div>
</template>

<script>
import { Toast } from 'vant';
import SuggestComplaint from "./Complaint";

// import { getVoteList } from '../api'

export default {
  data() {
    return {
      // interFace: {
      //     api: getVoteList
      // }
    };
  },
  created(){
    
  },
  components: {
    SuggestComplaint,
  },
};
</script>

<style scoped lang="less">
.suggestList {
  width: 100%;
  box-sizing: border-box;
  /deep/ .van-tab--active {
    background-color: #f7fafc !important;
  }
   /deep/ .van-tabs__line{
      width: 3.73rem !important;
  }

}
</style>