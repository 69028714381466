<template>
  <div class="complaint">
    <div class="theme">
      娄庄镇社情民意直通车
    </div>
    <div class="content">
      <!-- <p class="title">标题</p>
      <van-field
        class="text-area"
        show-word-limit
        v-model="titleData"
        autosize
        rows="1"
        type="textarea"
        placeholder="请输入标题"
        contenteditable="true"
      /> -->
    </div>
    <div class="content">
      <p class="title">反馈主要问题</p>
      <van-field
        class="text-area"
        maxlength="200"
        show-word-limit
        v-model="titleData"
        autosize
        rows="6"
        type="textarea"
        placeholder="请输入问题...（限200字）"
        contenteditable="true"
      />
    </div>
    <div class="content">
      <p class="title">建议/现状</p>
      <van-field
        class="text-area"
        show-word-limit
        v-model="contentData"
        autosize
        rows="5"
        type="textarea"
        placeholder="请输入建议...（限200字）"
        contenteditable="true"
      />
    </div>
    <van-button class="btn" @click="hanldeSubmit">提交</van-button>
  </div>
</template>
<script>
import { eventPost } from "../api";
import Upload from "@/components/Upload";
export default {
  name: "complaint",
  data() {
    return {
      show: false,
      time: 0,
      timer: {},
      titleData: "",
      contentData: "",
      localId: "",
      img: [],
    };
  },
  components: {
    Upload,
  },
  created(){
    
  },
  methods: {
    hanldeSubmit() {
      if (!this.titleData) {
        this.$toast.fail("您还未输入标题");
      } else if (!this.contentData) {
        this.$toast.fail("您还未输入内容");
      } else {
        let data = {
          title: this.titleData,
          content: this.contentData,
          appid: 1,
        };

        eventPost({
          ...data,
        }).then((res) => {
          if (res.code == 0) {
            this.$toast.success("提交成功");
            this.titleData = "";
            this.contentData = ""
          } else {
            this.$toast.success(res.message);
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.complaint {
  padding: 0.27rem 0.4rem 0;
  padding-bottom: 1rem;
  position: relative;
  min-height: 14.5rem;
  background: url("../../../assets/images/organization/bg.png");
  background-size: 100% 100%;
  .theme {
    width: 5.5rem;
    margin: 0.3rem auto;
    color: #333333;
    font-size: 0.36rem;
    font-weight: 700;
    text-align: center;
    line-height: 0.6rem;
  }
  .title {
    margin-bottom: 0.2rem;
    color: #333333;
  }
  .content {
    border-radius: 0.06rem;
    padding: 0.19rem 0.2rem;
    .text-area {
      border-radius: 0.2rem;
      background-color: #f6faff;
    }
    .operation {
      width: 40%;
      margin: 0.4rem auto;
      text-align: center;
      .play-icon {
        padding: 0.04rem;
        border-radius: 0.08rem;
        height: 0.8rem;
        width: 0.8rem;
        background: #00dc51;
        margin: 0 auto;
        p {
          height: 100%;
          // background: url("../assets/mai.png") no-repeat;
          background-size: 100% 100%;
        }
      }

      .y1 {
        margin-top: 0.1rem;
        font-size: 0.32rem;
      }
      .y2 {
        color: #999;
        margin-top: 0.03rem;
        font-size: 0.24rem;
      }
    }
    .operation:active .play-icon {
      background: rgba(0, 220, 81, 1);
    }
  }
  .btn {
    color: #fff;
    background: #3b93ff;
    width: 6.55rem;
    display: block;
    border-radius: 0.2rem;
    line-height: 0.88rem;
    margin: 1rem auto;
    font-size: 0.38rem;
    letter-spacing: 0.1rem;
  }
}
</style>